import React from "react";
import theme from "theme";
import { Theme, Em, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				BrightWave Marketing
			</title>
			<meta name={"description"} content={"Перетворення бачень у ефективні маркетингові стратегії"} />
			<meta property={"og:title"} content={"BrightWave Marketing"} />
			<meta property={"og:description"} content={"Перетворення бачень у ефективні маркетингові стратегії"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/667169238d4a0c002030975f/images/1-1.jpg?v=2024-06-18T11:29:31.455Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/667169238d4a0c002030975f/images/1-1.jpg?v=2024-06-18T11:29:31.455Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/667169238d4a0c002030975f/images/1-1.jpg?v=2024-06-18T11:29:31.455Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/667169238d4a0c002030975f/images/1-1.jpg?v=2024-06-18T11:29:31.455Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/667169238d4a0c002030975f/images/1-1.jpg?v=2024-06-18T11:29:31.455Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/667169238d4a0c002030975f/images/1-1.jpg?v=2024-06-18T11:29:31.455Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/667169238d4a0c002030975f/images/1-1.jpg?v=2024-06-18T11:29:31.455Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenuOverride7" />
			<Override slot="quarklycommunityKitMenuOverride5" />
			<Override slot="quarklycommunityKitMenuOverride6" />
			<Override slot="text" />
			<Override slot="icon" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
			<Override slot="icon1" />
		</Components.Header>
		<Components.Hero background="--primaryGradient,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/667169238d4a0c002030975f/images/1-1.jpg?v=2024-06-18T11:29:31.455Z) 0% 0% /cover repeat scroll padding-box">
			<Override slot="text">
				<Em>
					Перетворення бачень у ефективні маркетингові стратегії
				</Em>
			</Override>
			<Override slot="text1">
				У BrightWave Marketing ми віримо в силу інноваційного та стратегічного маркетингу. Наша місія полягає в тому, щоб перетворити ваше бачення на переконливу розповідь, яка резонує з вашою цільовою аудиторією. Ласкаво просимо в майбутнє маркетингу, де креативність зустрічається зі стратегією.
			</Override>
			<Override slot="text2">
				Цифровий маркетинг
			</Override>
			<Override slot="text3">
				Створення контенту
			</Override>
			<Override slot="text4">
				Брендінг
			</Override>
			<Override slot="text5">
				Дослідження ринку
			</Override>
			<Override slot="text6">
				Керування кампанією
			</Override>
			<Override slot="link" href="/services" />
		</Components.Hero>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="60%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Про нас
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--lead"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					BrightWave Marketing – це маркетингова агенція, яка надає повний спектр послуг і допомагає компаніям будь-якого розміру досягати своїх маркетингових цілей. Наша команда досвідчених професіоналів спеціалізується на різних аспектах маркетингу, від цифрових кампаній і створення контенту до брендингу та дослідження ринку. Ми пишаємося тим, що надаємо індивідуальні рішення, які забезпечують результати та допомагають нашим клієнтам виділитися на переповненому ринку.
				</Text>
				<Link
					href="/services"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Послуги
				</Link>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="40%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						1
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Індивідуальні стратегії
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Ми витрачаємо час, щоб зрозуміти ваш бізнес і розробити маркетингові стратегії, які відповідають вашим цілям і баченням.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						2
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Досвідчена команда
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Наша команда складається з досвідчених професіоналів, які мають досвід у різних маркетингових дисциплінах, що забезпечує комплексний підхід до ваших маркетингових потреб.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						3
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Доведені результати
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Ми маємо досвід проведення успішних маркетингових кампаній, які стимулюють залучення та підвищують рентабельність інвестицій.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-primary" quarkly-title="FAQ-7">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				Наш підхід
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--light" sm-margin="0px 0px 50px 0px">
				Ми підходимо до кожного проекту з свіжого погляду, налаштовуючи наші послуги відповідно до унікальних потреб наших клієнтів. Використовуючи новітні інструменти та технології, ми гарантуємо, що кожна кампанія базується на даних і оптимізована для успіху. Наша мета — створювати маркетингові стратегії, які є не лише ефективними, але й стійкими, допомагаючи нашим клієнтам досягти довгострокового зростання.
			</Text>
			<Text margin="0px 0px 70px 0px" font="--headline3" color="--light" sm-margin="0px 0px 50px 0px">
				Ми залишаємося на випередженні, впроваджуючи останні маркетингові тенденції та технології в наші стратегії.
			</Text>
		</Section>
		<Components.Contacts>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="link" />
			<Override slot="text3" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text5" />
			<Override slot="text4" />
			<Override slot="box3" />
			<Override slot="icon2" />
			<Override slot="icon1" />
			<Override slot="icon" />
			<Override slot="text1" />
			<Override slot="button" />
			<Override slot="button1" />
			<Override slot="image" src="https://images.unsplash.com/photo-1528747045269-390fe33c19f2?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000" border-radius="50%" />
		</Components.Contacts>
		<Components.Footer>
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});